import React, {useState, useEffect} from 'react';
import { Section, Space } from '../../GlobalStyles';
import Highlight from '../Highlight';
import { Container } from '../../GlobalStyles';
import ConfirmDialog from '../ConfirmDialog';
import {useAlert, ALERT_TYPES} from '@etiquette-ui/alert-dialog';
import { Input, FileInput, TextArea } from '@etiquette-ui/inputs-dropdowns';
import { createFormData } from './utils';
import { isValidData, isValidEmail } from '../utils/forms';
import {
    Grid,
    Row,
    ContactContainer,
    InputContainer,
    FullInputContainer,
    Button
} from './styles';
import useI18n from '../../i18n/useI18n';
import FormData from 'form-data';

const Contact = () => {
    const { translateObject, selectedLang } = useI18n();
    const alert = useAlert();

    const highlightTitle = {
        en: 'Join',
        es: 'Sé parte'
    }

    const title = {
        en: 'Us',
        es: 'de nuestro equipo'
    }

    const errorMessage = {
        en: 'This field is required.',
        es: 'Este dato es obligatorio.'
    }

    const fileErrorMessage = {
        en: 'The file must be PDF format',
        es: 'Sólo se aceptan archivos de tipo PDF.'
    }
    
    const emailErrorMessage = {
        en: 'Invalid email',
        es: 'Agrega un email válido'
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');  
    const [message, setMessage] = useState('');  
    const [position, setPosition] = useState('');
    const [linkedIn, setLinkedIn] = useState('');
    const [cvFile, setCVFile] = useState(new FormData());
    const [isInvalidData, setIsInvalidData] = useState(false);
    const [hasFileError, setHasFileError] = useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);

    const data = [
        name, 
        email, 
        message, 
        position, 
        linkedIn
    ];

    useEffect(() => {
        for( const file of cvFile.values() ){
            const type = file.type;
            if(!type.includes('pdf')){
                setHasFileError(true);
                break;
            } else {
                setHasFileError(false);
                break;
            }
        }
    }, [cvFile]);

    const handleOnclick = (event) => {
        event.preventDefault();

        if(!!email && !isValidEmail(email)){
            setIsInvalidEmail(true);
            return;
        } 
        else setIsInvalidEmail(false);

        if(!isValidData(data)) {
            setIsInvalidData(true);
            return;
        }

        if(hasFileError) {
            return;
        }

        setIsInvalidData(false);
        setIsInvalidData(false);
        let formData = createFormData(data, cvFile);

        // try {
            // axios.post('https://some-api.com', formData, {
            // 'content-type': 'multipart/form-data',
            // });
            alert({
                type: ALERT_TYPES.CUSTOM,
                config: {
                    props: {                        
                        name: name,
                        lang: selectedLang,
                        translateObject: translateObject,
                    },
                    body: ConfirmDialog,
                },
            }).then(()=>{
                window.location.reload();
            })
        // } catch (error) {
        //     console.error('Failed to submit files.');
        // }
    }

    return (
        <Section color='white'>
            <Space size='100' />
            <Container>
                <Row>
                    <ContactContainer>
                    <h2 data-sal="slide-up" data-sal-easing="ease-in-circ"><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                    <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay='200'>
                        {translateObject({
                            en: `We want to hear from you, send us a message.
                            We are looking for the best talent to join our team.
                            If you are interested, enter your information in the
                            following form, we will contact you soon!`,
                            es: `Queremos saber de ti, envíanos un mensaje. 
                            Estamos buscando los mejores talentos para nuestro equipo.
                            Si estás interesado, envíanos tus datos personales y te contactaremos pronto.`
                        })}                        
                    </p>
                    </ContactContainer>  
                    <InputContainer>
                        <Grid>
                            <Input
                                iconProps={{ icon: 'user' }}
                                labelProps={{ label: `${translateObject({
                                    en: 'Full name',
                                    es: 'Nombre completo'
                                })}` }}
                                inputProps={{
                                    value: name,
                                    required: true,
                                    onChange: (e)=> setName(e.target.value)
                                }}
                                errorProps={{
                                    error: `${isInvalidData && name === '' ? translateObject(errorMessage) : ''}`,
                                    style: {
                                        bottom: '-13px',
                                    }
                                }}
                            />
                            <Input
                                iconProps={{ icon: 'mail' }}
                                labelProps={{ label: `${translateObject({
                                    en: 'Email address',
                                    es: 'Email'
                                })}`}}
                                inputProps={{
                                    value: email,
                                    required: true,
                                    pattern:"[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$",
                                    onChange: (e)=> setEmail(e.target.value)
                                }}
                                errorProps={{
                                    error: `${isInvalidData && email === '' ? translateObject(errorMessage) : ''}${isInvalidEmail && !!email ? translateObject(emailErrorMessage) : ''}`,
                                    style: {
                                        bottom: '-13px',
                                    }
                                }}
                            />
                            <Input
                                iconProps={{ icon: 'position' }}
                                labelProps={{ label: `${translateObject({
                                    en: 'Your position',
                                    es: '¿Cuál es tu cargo?'
                                })}`}}
                                inputProps={{
                                    value: position,
                                    required: true,
                                    onChange: (e)=> setPosition(e.target.value)
                                }}
                                errorProps={{
                                    error: `${isInvalidData && position === '' ? translateObject(errorMessage) : ''}`,
                                    style: {
                                        bottom: '-13px',
                                    }
                                }}
                            />
                            <Input
                                iconProps={{ icon: 'link' }}
                                labelProps={{ label: `${translateObject({
                                    en: 'Linkedin profile',
                                    es: 'Linkedin'
                                })}`}}
                                inputProps={{
                                    value: linkedIn,
                                    required: true,
                                    onChange: (e)=> setLinkedIn(e.target.value)
                                }}
                                errorProps={{
                                    error: `${isInvalidData && linkedIn === '' ? translateObject(errorMessage) : ''}`,
                                    style: {
                                        bottom: '-13px',
                                    }
                                }}
                            />
                        </Grid>

                        <FullInputContainer>
                            <TextArea
                                labelProps={{ label: `${translateObject({
                                    en: 'Message',
                                    es: 'Mensaje'
                                })}`}}
                                inputProps={{
                                    value: message,
                                    required: true,
                                    rows: 4,
                                    style: {
                                        minHeight: 'auto',
                                    },
                                    onChange: (e)=> setMessage(e.target.value)
                                }}
                                containerProps={{
                                    style: {
                                        margin: '20px 0',
                                    },
                                }}
                                errorProps={{
                                    error: `${isInvalidData && message === '' ? translateObject(errorMessage) : ''}`,
                                    style: {
                                        bottom: '-4px',
                                        left: '10px'
                                    }
                                }}
                            />
                            <div>
                                <FileInput
                                    accept='.pdf'
                                    labelProps = {{ label: `${translateObject({
                                        en: 'Upload your CV',
                                        es: 'Agrega tu CV'
                                    })}`}}
                                    placeholderProps={{ placeholder: `${translateObject({
                                        en: 'Drop your file or Click here to upload',
                                        es: 'Haz clic aquí o arrastra tu archivo'
                                    })}`}}
                                    setFormData={setCVFile}
                                    errorProps={{
                                        error: `${hasFileError ? translateObject(fileErrorMessage) : ''}`,
                                    }}
                                />
                            </div>
                            <Button onClick={handleOnclick}>
                                {translateObject({
                                    en: 'Submit',
                                    es: 'Enviar'
                                })}
                            </Button>
                        </FullInputContainer>
                    </InputContainer>
                </Row>
            </Container>
        </Section >
    );
};
export default Contact;