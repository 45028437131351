export const teamInfo = [
    {
        name: 'Irving Ché',
        position: {en: 'Front End Developer', es: 'Desarrollador Front End'},
        link: 'https://www.linkedin.com/'
    },
    {
        name: 'Cedric Borreguín',
        position: {en: 'Principal Software Engineer', es: 'Principal Software Engineer'},
        link: 'https://www.linkedin.com/'
    },
    {
        name: 'David Dorbecker',
        position: {en: 'Full Stack Developer', es: 'Desarrollador Full Stack'},
        link: 'https://www.linkedin.com/'
    },
    {
        name: 'Ynna Nelly',
        position: {en: 'COO', es: 'Directora de Operaciones'},
        link: 'https://www.linkedin.com/'
    },
    {
        name: 'Guillermo Hernández',
        position: {en: 'UI/UX Designer', es: 'Diseñador UI/UX'},
        link: 'https://www.linkedin.com/'
    },
    {
        name: 'Brian Sneed',
        position: {en: 'CEO and Founder', es: 'CEO y Fundador'},
        link: 'https://www.linkedin.com/'
    },
];