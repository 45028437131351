import React from 'react'
import Position from '../../components/Position';
import Highlight from '../../components/Highlight';
import {
    Container,
    Space,
    Center
} from '../../GlobalStyles';

import useI18n from '../../i18n/useI18n';

const Description = ({ location }) => {

    const { translateObject } = useI18n();

    const highlightTitle = {
        en: 'We make',
        es: 'Hacemos'
    }

    const title = {
        en: 'lives easier and more profitable for restauranteurs',
        es: 'la vida más fácil y rentable para los restaurantes'
    }
    
    return (<>
        <Container>
            <Space />
            <Position location={location} />
            <Center className='max900'>
                <h2 className='overflow'data-sal="slide-up" data-sal-easing="ease-in-circ"><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay='200'>
                    {translateObject({
                        en: `We are Rooftop Ventures LCC, a US and Mexico based business software development company.`,
                        es: `Somos Rooftop Ventures LCC, una empresa de desarrollo de software empresarial con sede en Estados Unidos y México.`
                    })}                     
                </p>
                <br />
                <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay='200'>
                    {translateObject({
                        en: `We develop business software and mobile apps to solve and make easier the daily activities on your business. This will increase the productivity of your team and the profitability of your business.`,
                        es: `Desarrollamos software empresarial y aplicaciones móviles para solucionar y facilitar el día a día de tu negocio. Esto aumentará la productividad de tu equipo y la rentabilidad de tu negocio.`
                    })}                    
                </p>
            </Center>
        </Container>
        <Space size='60' />
    </>)
};

export default Description;