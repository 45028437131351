import React from 'react'
import Highlight from '../../components/Highlight';
import {
    Container,
    Space,
    Center
} from '../../GlobalStyles';
import TeamCard from './components/TeamCard';
import styled from 'styled-components';
import { device } from '../../Theme';
import { teamInfo } from './data';

import useI18n from '../../i18n/useI18n';

const Grid = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    justify-items: center;
    align-items: center;

    @media ${device.ipad} { 
        margin-top: 0px;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
    } 
    @media ${device.mobileL} { 
        margin-top: 0px;
        grid-template-columns: 100%;
        text-align: center;
    } 
`;
const MeetUs = () => {

    const { translateObject } = useI18n();

    const highlightTitle = {
        en: 'Meet',
        es: 'Conoce'
    }

    const title = {
        en: 'our team',
        es: 'a nuestro equipo'
    }
    
    return (
        <Container>
            <Space />
            <Center>
                <h2 data-sal="slide-up" data-sal-easing="ease-in-circ">
                    <Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}
                </h2>
            </Center>

            <Space />
            <Grid>
                {teamInfo.map(info =>
                    <TeamCard
                        name={info.name}
                        position={translateObject(info.position)}
                        link={info.link}
                    />
                )}
            </Grid>
            <Space size='120' />
        </Container>
    )
};

export default MeetUs;