import React from "react";
import BgImage from "./BgImage";
import styled from 'styled-components';
import { device } from "../Theme";

import useI18n from '../i18n/useI18n';

const Regular = styled.div`
    display: block;

    @media ${device.mobileXL} { 
        display: none;
    }
`;
const Mobile = styled.div`
    display: none;
    
    @media ${device.mobileXL} { 
        display: block;
    }
`;
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    
    @media ${device.mobileXL} { 
        flex-direction: column;
        padding-bottom: 30px;
    }

    h1{
        font-size: 90px;
        @media ${device.mobile} { 
            font-size: 40px;
        }
    }
`;

const Header = ({ data }) => {

    const { translate } = useI18n();
    
    return (<>
        <Regular>
            <BgImage
                title={data.heroAlt}
                fluid={data.heroImage}
                height='400px'
            >
                <Container data-sal="fade" data-sal-easing="ease-in-circ" data-sal-delay="300">
                    <h1>{translate(data.title)}</h1>
                </Container>
            </BgImage>
        </Regular>
        <Mobile>
            <BgImage
                title={data.heroAltMobile}
                fluid={data.heroMobile}
                mobileHeight='300px'
            >
                <Container data-sal="fade" data-sal-easing="ease-in-circ" data-sal-delay="300">
                    <h1>{translate(data.title)}</h1>
                </Container>
            </BgImage>
        </Mobile>
    </>)
};
export default Header;