import { device, minDevice } from '../../Theme';
import styled from 'styled-components';
import { TEXT } from '@etiquette-ui/colors';
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';
import { TextArea } from '@etiquette-ui/inputs-dropdowns';

const Container = styled.div`
    height: 100%;
    max-width: 90%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 260px 1fr;
    grid-column-gap: 108px;

    justify-items: center;
    align-items: baseline;
    
    @media ${device.laptop} {
        grid-column-gap: 5%;
    }
    @media ${device.mobile} {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
    }
`;

const Grid = styled.div`
    height: fit-content;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    
    @media ${device.mobileXL} {
        grid-template-columns: 1fr;
    }
`;

const ContactContainer = styled.div`
    margin: 0;
    padding: 0;
    padding-bottom: 15px;

    max-width: 100%;
    flex: 0 0 100%;
    color: ${TEXT};

    h2, p {
        margin: 0;
        width: 100%;
        padding-bottom: 10px;
    }

    @media ${minDevice.tablet} {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;

        h2, p {
            max-width: 75%;
        }

        h2 {
            padding-bottom: 24px;
        }
    }
`;

const InputContainer = styled.div`
    max-width: 100%;
    flex: 0 0 100%;

    @media ${minDevice.tablet} {
        max-width: 66.666666%;
        flex: 0 0 66.666666%;
    }

    @media ${minDevice.laptop} {
        max-width: 720px;
    }
`;
const FullInputContainer = styled.div`
`;

const Button = styled(PrimaryButton)`
    max-width: 260px;
    margin-top: 15px;
`;

const CVButton = styled(SecondaryButton)`
    max-width: 200px;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;
`;

const TextInput = styled(TextArea)`
    max-height: 120px;
    margin: 20px 0;
`;

const IconStyle = {
    svg: {
        width: '16px',
        height: '16px',
        marginRight: '8px',
    }
};

const Row = styled.form`
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    margin-bottom: 70px;
    @media ${minDevice.tablet} {
        margin-top: 80px;
        margin-bottom: 100px;
    }
`;

export {
    Grid,
    Row,
    ContactContainer,
    Container,
    InputContainer,
    FullInputContainer,
    Button,
    TextInput,
    CVButton,
    IconStyle
};