import React from "react";
import Seo from "../components/seo";
import AboutLayout from "../containers/AboutLayout";
import { Section } from '../GlobalStyles.jsx';
import { graphql, useStaticQuery } from "gatsby";
import '../globalStyles.css';
import { BG_DARK, BG } from '@etiquette-ui/colors';
import Description from "../sections/AboutUs/description";
import MeetUs from "../sections/AboutUs/meet-us";

const AboutUs = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    title: 'About Us',
    heroImage: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[0].node.base,
    heroMobile: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[0].node.base,
  };
  

  return (
    <AboutLayout data={data}>
      <Seo title={data.title} />

      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <MeetUs />
      </Section>
    </AboutLayout >
  )
}
export default AboutUs;


const query = graphql`
query about {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "aboutus-hero",
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;