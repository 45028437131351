import React from 'react'
import { Card } from '@etiquette-ui/misc';
import { TEXT } from '@etiquette-ui/colors';
import styled from 'styled-components';
import In from '../../../images/linkedin-blue-icon.svg';
import { device } from '../../../Theme';

const Container = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${TEXT};
    text-align: center;
    height: 200px;

    @media ${device.laptop} { 
        height: 200px;
    }
    @media ${device.mobileM} { 
        width: 100%;
    }

    span {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }

    p{
        margin: 0;
        padding: 0;
        padding-bottom: 35px;
    }
`;

const TeamCard = ({ name, position, link }) => (
    <Container data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='800'>
        <span>{name}</span>
        <p>{position}</p>
        <a href={link}><In /></a>
    </Container>
);

export default TeamCard;