import React from 'react';
import styled from 'styled-components';
import { PRIMARY } from '@etiquette-ui/colors';

import useI18n from '../i18n/useI18n';

const Container = styled.div`
    &, .blue {
        font-weight: 600;
        font-size: 16px;
    }
    
    
    .blue{
        color: ${PRIMARY};
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const Position = ({ location }) => {
    const { translate } = useI18n(); 
    return (
        <Container>
            <p><a className='blue' href='/'>{translate('Home')} /</a> {translate(location)}</p>
        </Container>
    )
};

export default Position;