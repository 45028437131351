import FormData from 'form-data';

export const createFormData = (data, cvFile) => {
  const [
    name,
    email,
    position,
    linkedIn,
    message] = data;
  let formData = new FormData();

  formData.append('name', name);
  formData.append('email', email);
  formData.append('position', position);
  formData.append('linkedIn', linkedIn);
  formData.append('message', message);

  for( const file of cvFile.values() ){
    formData.append(file.name, file);
    break;
  }

  return formData;
}
